@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'), url(../src/assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'), url(../src/assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: italic;
  src: local('Montserrat'), url(../src/assets/fonts/Montserrat-BoldItalic.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Montserrat',
    monospace;
}

/* Customize the picker input icons color */
::-webkit-calendar-picker-indicator {
  filter: invert(72%) sepia(6%) saturate(9%) hue-rotate(332deg) brightness(94%) contrast(88%);
}


/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
   cursor: pointer;
   background: #313A50;
  border-radius: 5px;
}

::-webkit-scrollbar {
  cursor: pointer;
  height: 3px;
  width: 3px;
}

::-webkit-scrollbar-track {
   cursor: pointer;
  background: #777777;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

::-webkit-scrollbar-thumb:hover {
  background: #313A50;
}

::-webkit-scrollbar-thumb:active {
  background: #333;
}


.custom-scrollbar {
  overflow-x: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #313A50;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}

